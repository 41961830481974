body {
  background-color: #fff !important;
}

filter-icons {
  width: 36px;
  height: 36px;
}

.profile-header {
  font-size: 20px;
  text-transform: uppercase;
}

.header-wrapper {
  background-color: #204f6e;
  position: relative;
  height: 77px;
  padding: 3rem;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-btn {
  background-color: #175778;
  display: none;
}
.hero-text {
  font-size: 20px;
  font-weight: bold;
  color: #175778;
}
.home-page-search {
  width: 100%;
  background: #c4c4c4;
  opacity: 0.3;
  border-radius: 5px;
  border: 0px;
  padding: 10px;
}
.search-icon {
  position: absolute;
  right: 35px;
  top: 10px;
}
.header-menu-item {
  color: #fff;
  font-size: 14px;
  margin: 0px 15px;
}
.home-page-btn {
  background: #00cffd;
  box-shadow: 0px 8px 15px rgba(0, 207, 253, 0.25);
  border-radius: 5px;
  color: #000000;
  font-size: 14px;
  outline: none;
  border: 0px;
  padding: 12px 22px;
}
.home-page-btn-light {
  background: #dadada;
  box-shadow: 0px 8px 15px rgba(220, 220, 220, 0.05);
  border-radius: 5px;
  color: #000000;
  font-size: 14px;
  outline: none;
  border: 0px;
  padding: 12px 22px;
}
.modal-body-content {
  padding: 35px;
}

.menu-wrapper {
  list-style: none;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-icons-wrapper {
  position: absolute;
  right: 15px;
  bottom: -65px;
  background: #175778;
  box-shadow: 0px 10px 12px rgba(23, 87, 120, 0.4);
  border-radius: 6px;
}
.menu-item {
  color: #fff;
  font-size: 14px;
  margin: 0px 15px;
}
.left-arrow {
  margin-right: 3rem;
  min-width: 30px;
}
.right-arrow {
  min-width: 30px;
  margin-left: 3rem;
}
.footer-menu {
  color: #fff;
  font-size: 14px;
  margin: 0px 15px;
}
.menu-btn {
  opacity: 0.5;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 6px 15px;
}

.footer-one-wrapper {
  margin: 0;
  padding: 15px;
  background-color: #0e2835;
  list-style: none;
  height: 61px;
  overflow: hidden;
}
.footer-two-wrapper {
  margin: 0;
  padding: 20px;
  list-style: none;
  text-align: center;
  min-height: 51px;
  overflow: hidden;
  background-color: #113548;
}

.section-body {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  padding: 70px;
}
.questions-wrapper {
  list-style: none;
  padding: 0;
  width: 100%;
  text-align: center;
}
.question-item {
  display: flex;
  align-items: center;
  background: #c4c4c438;
  color: #000;
  /* opacity: 0.2; */
  border-radius: 10px;
  text-align: left;
  margin-bottom: 15px;
}

.question-item p {
  /* color: #58595b; */
  font-size: 14px;
  padding: 0px 20px;
  margin: 0;
}
.question-section-new{
  border-radius: 5px;
  padding: 20px 25px;
  padding-bottom: 10px;
  margin-bottom:25px;
}
.question-header {
  font-size: 25px;
  font-weight: bold;
}

.question-header-wrapper {
  position: relative;
}
.question-close-btn {
  position: absolute !important;
  right: 0;
}
.year-label {
  font-size: 14px;
  color: #c4c4c4;
  padding: 10px 18px;
  margin: 0px 8px;
}
.selected-year-label {
  background: #175778;
  border-radius: 10px 0px;
  color: white;
  font-weight: bold;
}
.curvy-btn {
  background: #d3dfe5;
  border-radius: 10px 0px;
  color: #58595b;
  font-weight: normal;
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
.underline {
  text-decoration: underline;
}

/* question section */

.question-section {
  /* display: flex; */
  text-align: center;
  justify-content: space-around;
  width: 70%;
  margin: 0 auto;
  /* align-items: center; */
}

.font-28 {
  font-size: 28px !important;
  font-weight: bold;
}
.hero-data {
  font-size: 55px;
}
.primary-txt {
  color: #941faa;
}
.secondary-txt {
  color: #6634b1;
}
.grey-txt {
  color: #808080;
}
.question-section-one,
.question-section-two,
.question-section-three {
  /* width: 33%; */
  padding: 0px 20px;
  position: relative;
}
.active-filter-btn {
  border-radius: 100px;
  background: #175778;
  height: 100%;
  color: white;
  padding: 8px 12px;
  margin: 5px 3px;
  display: block;
  font-size: 10px;
  outline: none;
  border: 0px;
  text-transform: capitalize;
}
.active-filter-btn:focus {
  outline: none;
}
.active-filter-btn:active {
  outline: none;
}
.question-section-one,
.question-section-three {
  /* height: 447px; */
  max-height: max-content;
}
.text-box-wrapper {
  /* position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); */
}
.font-25 {
  font-size: 25px;
}
.font-18 {
  font-size: 18px;
}
.font-36 {
  font-size: 36px !important;
}
.chart-bar-zero {
  height: 42px;
  position: relative;
  transition: all 1s;
  border-radius: 50px;
  min-width: 46px;
  border: 1px solid;
  background: transparent!important;
}
.chart-bar {
  height: 42px;
  position: relative;
  transition: all 1s;
  border-radius: 50px;
  min-width: 12%;
  border: 1px solid;
}
.chart-label-zero-text{
  color: #58595B!important;
}
.chart-label {
  position: absolute;
  display: block;
  right: 10px;
  font-weight: bold;
  top: 8px;
  color: white;
  font-size: 16px;
}
.show-data {
  /* transition: all 1s; */
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.show-graph {
  transition: opacity 1s;
  opacity: 1;
  /* display: flex; */
  align-items: center;
}

.chart-label-text {
  font-size: 22px;
  color: #000000;
  font-style: italic;
  text-align: left;
}
.chart-outer-label {
  position: absolute;
  display: block;
  right: -40px;
  top: 6px;
  color: black;
  font-size: 12px;
}
::-webkit-scrollbar,
::-webkit-scrollbar:horizontal {
  width: 8px;
  height: 7px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track:horizontal {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal {
  background-color: #c4c4c4;
  box-shadow: inset 0 0 6px #c4c4c4;
  border-radius: 10px;
  opacity: 0.5;
}

::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
}
.share-btn {
  opacity: 1 !important;
}
.open-filter-section {
  width: 270px;
  background: #f3f3f3;
  box-shadow: 0px 4px 12px 6px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  overflow-y: auto;
}
.apply-filter-btn {
  background-color: #175778;
  color: white;
  display: none;
  outline: none;
  padding: 10px;
  border: 0px;
  border-radius: 4px;
  margin-top: 20px;
  font-size: 13px;
  font-style: italic;
  border: 1px solid #58595b;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 4px 8px;
}
/* mobile view */

@media only screen and (max-width: 1024px) {
  .apply-filter-btn {
    display: block;
  }
  .share-btn {
    display: none;
  }
  .section-body {
    padding: 20px;
  }
  .filter-section {
    display: none;
  }
  .explorer-section {
    padding: 20px !important;
    width: 100% !important;
  }
  .question-section-one,
  .question-section-two,
  .question-section-three {
    width: 100%;
  }
  .question-section-one,
  .question-section-three {
    display: none;
  }
  .header-wrapper {
    padding: 15px;
    height: auto;
    flex-direction: column;
  }
  .menu-wrapper {
    flex-wrap: wrap;
    margin-top: 15px !important;
    padding: 0;
  }
  .menu-item {
    margin: 5px 15px !important;
  }
  .footer-menu {
    display: contents;
    font-size: 9px !important;
  }
  .social-icons-wrapper {
    /* bottom: 13px; */
  }
  .filter-btn {
    display: block;
    position: absolute;
    bottom: 148px;
    padding: 10px;
    border: 0px;
    outline: none;
    color: white;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50px);
    z-index: 3;
  }
  .open-filter-section {
    position: absolute;
    right: 0;
    width: 93%;
    top: unset;
    background: #f3f3f3;
    box-shadow: 0px 4px 12px 6px rgba(0, 0, 0, 0.1);
    padding: 30px 15px;
    overflow-y: auto;
    z-index: 3;
    height: 80vh;
  }
  .video-wrapper {
    min-height: auto !important;
  }
  .show-graph {
    flex-direction: column;
  }
  .chart-label {
    font-size: 12px;
    top: 12px;
    /* width: 100% !important; */
  }
  .chart-bar-div {
    width: 100% !important;
  }
  .chart-label-text {
    font-size: 15px;
    text-align: center;
  }
  .question-section {
    width: 100%;
  }
  .line-space {
    display: none;
  }
  .filter-icons {
    width: 24px;
    height: 24px;
  }
  .menu-item {
    font-size: 10px !important;
  }
  .profile-header {
    font-size: 14px;
    text-transform: uppercase;
  }
  .filter-err-text {
    display: none;
  }
  .section-one-filter {
    display: none;
  }
  .left-arrow {
    margin-right: 10px;
    min-width: 30px;
  }
  .right-arrow {
    min-width: 30px;
    margin-left: 10px;
  }
  .font-26 {
    font-size: 20px;
  }
  .header-menu-item {
    margin: 5px 15px !important;
    font-size: 13px;
  }
}
@media only screen and (max-width: 360px) {
  .profile-header,
  .filter-head-text {
    font-size: 12px !important;
  }
  .checkbox-label {
    font-size: 11px !important;
  }
  .filter-clear-btn {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 404px) {
  .filter-btn {
    bottom: 170px;
  }
}
/* mobile view */
